import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export function setTokenCookie(token) {
  const decoded = jwtDecode(token);
  const expires = new Date(decoded.exp * 1000);
  Cookies.set("tkn", token, {
    expires: Number(7),
    sameSite: "Strict",
  });
}

export function cleanTokenCookie() {
  Cookies.remove("tkn", {
    sameSite: "Strict",
  });
}
