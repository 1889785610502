import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import { useOutletContext } from "react-router";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import Switch from "../../sharable/Switch";
import FeedCanvas from "../CanvasViewer/Canvas/FeedCanvas";
import {
  FEED_INSTAGRAM,
  PANFLETO,
  STORIES,
} from "../CanvasViewer/EncarteStyleConstants";

import { FEED_GRIDS, STORIES_GRIDS } from "./Grids";
import StoriesCanvas from "../CanvasViewer/Canvas/StoriesCanvas";
import Loading from "../../sharable/Loading";
import ProductObj from "../../../OBJRep/Product";
import PanfletoCanvas from "../CanvasViewer/Canvas/PanfletoCanvas";
import { copyProductPropertiesWithStyles } from "../../../services/Utils";

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 8.4rem);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      overflow-y: hidden;
      main {
        filter: blur(3px);
      }
    `}

    .switch-wrapper {
      margin-bottom: 2rem;
    }
  .switch-span {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    display: inline-block;
  }

  .grade-active-message {
    text-align: center;
    font-size: 1.6rem;
  }
`;
const GridListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  ${({ automaticGrid }) => {
    return (
      automaticGrid &&
      css`
        //background-color: red;
      `
    );
  }}
`;
const GridSlide = styled.div`
${({scale}) => scale && `transform: scale(${scale})`}
  width: 35rem;
  display: flex;
  gap: 2rem;
  overflow: scroll;
`;
const GridWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;
const GridList = styled.div`
  overflow-x: scroll;
  h1 {
    font-size: 2rem;
  }
`;

const scale = 0.2;
export default function GridMenuView({ props }) {
  const { activeAccount, isAccountPremium } = useOutletContext();
  const [isGridGenerationAutomatic, setIsGridGenerationAutomatic] = useState(
    props.grid ? false : true
  );

  useEffect(() => {
    if (isGridGenerationAutomatic) {
      props.updateGrid(undefined);
 
    }
  }, [isGridGenerationAutomatic]);

  const handleGridChange = (grid) => {
    props.updateGrid(grid.grid);
    props.updateEncarteProducts(grid.products);

   
  };
  return (
    <Container isAccountPremium={isAccountPremium}>
      {!isAccountPremium && <PremiumContentProtectiveLayer />}
      <main>
        <MenuDescription>
          Em breve você poderá customizar a grade de produtos
        </MenuDescription>
        <div className="switch-wrapper">
          <span className="switch-span">Grade automática</span>
          <Switch
            callback={setIsGridGenerationAutomatic}
            on={isGridGenerationAutomatic}
          />
        </div>
        {!isGridGenerationAutomatic ? (
          <>
            {" "}
            <MenuTitle>Grade de Produtos</MenuTitle>
            {props.encarteStyles.format === FEED_INSTAGRAM && (
              <GridListWrapper automaticGrid={isGridGenerationAutomatic}>
                {FEED_GRIDS.map((gridList) => (
                  <GridList>
                    <h1>{gridList.title}</h1>
                    <GridSlide>
                      {gridList.gridsArr.map((grid) => (
                        <GridFeedCanvas
                          props={props}
                          grid={grid}
                          handleGridChange={handleGridChange}
                          encarteProducts={props.encarteProducts}
                        />
                      ))}
                    </GridSlide>
                  </GridList>
                ))}
              </GridListWrapper>
            )}
            {props.encarteStyles.format === STORIES && (
              <GridListWrapper automaticGrid={isGridGenerationAutomatic}>
                {STORIES_GRIDS.map((gridList) => (
                  <GridList>
                    <h1>{gridList.title}</h1>
                    <GridSlide>
                      {gridList.gridsArr.map((grid) => (
                        <GridStoriesCanvas
                          props={props}
                          grid={grid}
                          handleGridChange={handleGridChange}
                          encarteProducts={props.encarteProducts}
                        />
                      ))}
                    </GridSlide>
                  </GridList>
                ))}
              </GridListWrapper>
            )}

{props.encarteStyles.format === PANFLETO && <p className="grade-active-message">Ainda não temos grades disponíveis para esse formato :(<br></br><br></br>Mas já estamos trabalhando nisso :)</p>}
          </>
        ): <p className="grade-active-message">Quando grade automática está ativada, os produtos são organizados de forma inteligente no encarte</p>}
      </main>
    </Container>
  );
}

function GridFeedCanvas({ props, grid, handleGridChange, encarteProducts }) {
  const [canvasProducts, setCanvasProducts] = useState(undefined);
  const [gridProductStyles, setGridProductStyles] = useState();
  useEffect(() => {
    if (!encarteProducts || encarteProducts.length === 0) {
      setCanvasProducts(grid.products);
    } else {
      const pArr = updateGridWithExistingProducts(
        grid.products,
        encarteProducts
      );
      setCanvasProducts(pArr);
    }
  }, [encarteProducts]);
  useEffect(() => {
    const styles = { ...props.encarteStyles };
    styles.fontSize = 28;
    setGridProductStyles(styles);
  }, [props.encarteStyles]);
  return (
    <>
      {" "}
      {canvasProducts && gridProductStyles ? (
        <GridWrapper
          onClick={() =>
            handleGridChange({ grid: grid.grid, products: canvasProducts })
          }
        >
          <FeedCanvas
            brand={props.brand}
            encarte={props.encarte}
            model={props.model}
            products={canvasProducts}
            scale={scale}
            styles={gridProductStyles}
            grid={grid.grid}
          />
        </GridWrapper>
      ) : (
        <Loading $NoBg txt="Carregando grade de produtos..." />
      )}
    </>
  );
}

function GridStoriesCanvas({ props, grid, handleGridChange, encarteProducts }) {
  const [canvasProducts, setCanvasProducts] = useState();
  props.encarteStyles.fontSize = 32;
  useEffect(() => {
    if (!encarteProducts || encarteProducts.length === 0) {
      setCanvasProducts(grid.products);
      return;
    } else {

      const pArr = updateGridWithExistingProducts(grid.products, encarteProducts);
      setCanvasProducts([...pArr]);
    }
  }, [encarteProducts, props.encarteStyles]);
  return (
    <>
      {canvasProducts ? (
        <GridWrapper
          onClick={() =>
            handleGridChange({ grid: grid.grid, products: canvasProducts })
          }
        >
          <StoriesCanvas
            brand={props.brand}
            encarte={props.encarte}
            model={props.model}
            products={canvasProducts}
            scale={scale}
            styles={props.encarteStyles}
            grid={grid.grid}
          />
        </GridWrapper>
      ) : (
        <Loading $NoBg color="#fff" txt={"Carregando grade de produtos..."} />
      )}
    </>
  );
}

const updateGridWithExistingProducts = (gridProducts, encarteProducts) => {
  const pArr = [...gridProducts];
  const maxI =
    gridProducts.length <= encarteProducts.length
      ? gridProducts.length - 1
      : encarteProducts.length - 1;
  for (let i = 0; i <= maxI; i++) {
    const gridProduct = { ...gridProducts[i] };
    const encarteProduct = { ...encarteProducts[i] };
    pArr[i] = copyProductPropertiesWithStyles(encarteProduct, gridProduct);
  }
  return pArr;
};

export const removeProductGridStyles = (encarteProducts) => {
  //return encarteProducts;
  const products = encarteProducts.slice();
  const alteredProducts = [];
  products.forEach(p => {
    /*NEEDS TO COPY BOTH PRODUCT AND STYLES SO IT DON'T AFFECT GRID.js STYLES */
    const pCopy = {...p}
    const sCopy = {...pCopy.styles}
    
    sCopy.gridRow = "";
    sCopy.gridColumn = "";
    pCopy.styles = sCopy;
    alteredProducts.push(pCopy);
  })
  return alteredProducts;
}
