import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Price from "./Price";
import {
  NAME_ABOVE_HIGHLIGHT_PRODUCT_LAYOUT,
  NAME_ABOVE_PRODUCT_LAYOUT,
  NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
  NAME_BENEATH_HIGHLIGHT_PRODUCT_LAYOUT,
  NAME_BENEATH_PRODUCT_LAYOUT,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../WorkSpace/CanvasViewer/EncarteStyleConstants";
import Loading from "./Loading";
import { relative } from "path";

const Container = styled.div`
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: flex;
  user-select: none;
  overflow: hidden;
  ${({ productLayout, scale }) => {
    let style;
    if (productLayout === TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT) {
      style = css`
        flex-direction: row-reverse;
        position: relative;
        .product-name {
          align-self: center !important;
          transform: translateY(-100%);
          margin-top: ${24 * scale}px;
          text-align: left;

          }
        .price {
          left: -5%;
          bottom: 20%;
          right: auto;
          position: absolute;
          
        }
        .product-image {
          position: unset;
          background-size: contain;
        }
      `;
    } else if (productLayout === NAME_BENEATH_PRODUCT_LAYOUT) {
      style = css`
        flex-direction: column;
        align-items: center;
        .product-name {
          border-bottom-right-radius: ${30 * scale}px;
          border-bottom-left-radius: ${30 * scale}px;
        }
      `;
    } 
    else if (productLayout === NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT) {
      style = css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr;
        ${Image} {
          grid-column: 1/3;
        }
      `;
    } 
    
    else {
      style = css`
        flex-direction: column-reverse;
        .product-name {
          border-top-right-radius: ${30 * scale}px;
          border-top-left-radius: ${30 * scale}px;
        }
      `;
    }

    return style;
  }}

  ${({ bgColor, scale }) => {
      return css`
        background-color: ${bgColor != "" ? bgColor : "transparent"};
        border-radius: ${30 * scale}px !important;
      `;
  }}

    ${({ row, column, font, name }) => {return `
    grid-column: ${column};
    grid-row: ${row};
    font-family: ${font};
  `}}

  .name-price {
    align-items: center;
    gap: 2.6rem;
  }
`;

export const Image = styled.div`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  ${({ url, hover, height, pId, backgroundSize }) => {
    return css`
      background-image: url("${url}");
      cursor: pointer;
      height: ${height ? height : `100%`};
      background-size: ${backgroundSize ? backgroundSize : "contain"};
      ${hover
        ? `&&:hover {
        background-color: rgba(1,1,1,.15)
      }`
        : ""};
    `;
  }}
`;

const Name = styled.span`
  ${({ fontSize, scale, bgColor, txtColor }) => {
    return css`
      font-size: ${fontSize * scale}px;
      background-color: ${bgColor};
      color: ${txtColor ? txtColor : "black"};
    `;
  }}
  bottom: 0;
  padding: 2%;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  align-self: center;
  text-align: center;
  display: block;
  opacity: 1.5;

  width: 100%;
`;

export default function Product({ styles, product, scale }) {
  const [productStyles, setProductStylesStyles] = useState();

  useEffect(() => {
    setProductStylesStyles(product.styles)
  }, [styles, product.styles]);

  return (
    <>
      {productStyles ? (
        <Container
          productLayout={productStyles.productLayout}
          font={styles.fontFamily}
          txtColor={productStyles.txtColor}
          bgColor={productStyles.bgColor}
          scale={scale}
          className="product"
          key={`grid-product-${product.id}`}
          row={productStyles.gridRow}
          column={productStyles.gridColumn}
          name={product.name}
        >
          <Image
            scale={scale}
            url={product.imageURLTmp}
            className="product-image"
            pId={product.id}
          >
            {productStyles.productLayout !=
              NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT && (
              <Price
                scale={scale}
                price={product.price}
                unity={product.measurement}
                styles={productStyles}
              />
            )}
          </Image>

          <Name
            productLayout={productStyles.productLayout}
            scale={scale}
            fontSize={productStyles.fontSize}
            bgColor={productStyles.bgColor}
            txtColor={productStyles.txtColor}
            className="product-name"
          >
            {product.name}
          </Name>
          {productStyles.productLayout ===
            NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT && (
            <Price
              scale={scale}
              price={product.price}
              unity={product.measurement}
              styles={productStyles}
              position="relative"
            />
          )}
        </Container>
      ) : (
        <Loading $NoBg color={"#fff"} txt={"Carregando estilos"} />
      )}
    </>
  );
}
