import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import Product from "../../sharable/EncarteProduct";
import Styles from "../../../OBJRep/Styles";
import ProductObj from "../../../OBJRep/Product";
import {
  NAME_ABOVE_PRODUCT_LAYOUT,
  NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT,
  NAME_BENEATH_PRODUCT_LAYOUT,
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
  UNITYS,
} from "../CanvasViewer/EncarteStyleConstants";
import ProductStyles from "../../../OBJRep/ProductStyles";

const Container = styled.div`
  height: min-content
  overflow-y: auto;
  main {
    width: 100%;

    gap: 3.6rem;
    flex-direction: column;
  }

  h2 {
    margin-bottom: 2.4rem;
    text-transform: capitalize;
  }
`;

const LayoutWrapper = styled.div`
  height: 150px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 1.2rem 0;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    border: 1px solid #d97706;
  }

  ${({ selected }) => {
    return (
      selected == true &&
      css`
        background-color: #d97706;
        color: #fff;
      `
    );
  }}
`;

const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;
const productStyles = ProductStyles();
productStyles.fontSize = "24";
productStyles.bgColor = "transparent";
productStyles.txtColor = "#fff";
productStyles.priceStyle = ROUGHLY_ROUNDED_PRICE_STYLE;
const product = ProductObj(
  0,
  "Coca cola 2L",
  "6,49",
  "",
  "images/coca.png",
  UNITYS[0],
  productStyles

);
const product2 = {...product};
const productStyles2 = {...productStyles};
productStyles2.productLayout = NAME_BENEATH_PRODUCT_LAYOUT;
product2.styles = productStyles2
const product3 = {...product};
const productStyles3 = {...productStyles};
productStyles3.productLayout = TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT;
product3.styles = productStyles3
const product4 = {...product}
const productStyles4 = {...productStyles};
productStyles4.productLayout = NAME_AND_PRICE_SIDE_BY_SIDE_PRODUCT_LAYOUT;
product4.styles = productStyles4

export default function ProductLayout({
  props,
  selectedProduct,
  updateProductStyle,
}) {
  const [currentLayout, setCurrentLayout] = useState(NAME_ABOVE_PRODUCT_LAYOUT);

  useEffect(() => {
    let layout = undefined;
    if (
      selectedProduct &&
      selectedProduct.styles &&
      selectedProduct.styles.productLayout
    )
      layout = selectedProduct.styles.productLayout;
    else layout = NAME_ABOVE_PRODUCT_LAYOUT;
    setCurrentLayout(layout);
  }, [selectedProduct, props.encarteStyles]);
  const handleLayoutChange = (e) => {
    const layoutWrapper = e.target.closest(".layout");
    if (!layoutWrapper) return;
    const layout = layoutWrapper.getAttribute("data-layout-id");
    setCurrentLayout(layout)
    updateProductStyle("productLayout", layout);

  };

  return (
    <Container>
      <h2>Layout do produto</h2>
      {currentLayout && (
        <LayoutContainer className="flex" onClick={handleLayoutChange}>
          <LayoutWrapper
            selected={
              currentLayout === undefined ||
              currentLayout === product.styles.productLayout
                ? true
                : false
            }
            className="layout"
            data-layout-id={product.styles.productLayout}
          >
            <Product scale={0.5} styles={props.encarteStyles} product={product} />
          </LayoutWrapper>
          <LayoutWrapper
            selected={
              currentLayout === product2.styles.productLayout ? true : false
            }
            className="layout"
            data-layout-id={product2.styles.productLayout}
          >
            <Product scale={0.5} styles={props.encarteStyles} product={product2} />
          </LayoutWrapper>
          <LayoutWrapper
            selected={
              currentLayout === product3.styles.productLayout
                ? true
                : false
            }
            className="layout"
            data-layout-id={product3.styles.productLayout}
          >
            <Product scale={0.5} styles={props.encarteStyles} product={product3} />
          </LayoutWrapper>
          <LayoutWrapper
            selected={
              currentLayout === product4.styles.productLayout
                ? true
                : false
            }
            className="layout"
            data-layout-id={product4.styles.productLayout}
          >
            <Product scale={0.5} styles={props.encarteStyles} product={product4} />
          </LayoutWrapper>
       
        </LayoutContainer>
      )}
    </Container>
  );
}
