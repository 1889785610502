const RESOURCES_BASE_URL = process.env.REACT_APP_RESOURCES_BASE_URL;
const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const normalizeQuery = (q) => {
  q = q.replaceAll(" ", "");
  q = q.toLowerCase();
  return q;
};

const authenticateUrl = async (url, token) => {
  //console.log("Authenticating " + url);
  try {
    const res = await fetch(APP_DOMAIN + url, {
      headers: { Authorization: token },
    });
    const blob = await res.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    return objectUrl;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const CORStoDataURL = async (link) => {
  const cors = process.env.REACT_APP_CORS_BASE_URL;
  let url = "";
  try {
    const res = await fetch(cors + link);
    const buffer = await res.arrayBuffer();
    const blob = new Blob([buffer]);
    url = window.URL.createObjectURL(blob);
  } catch (err) {
    alert("Não foi possível carregar essa imagem, por favor, selecione outra");
    return null;
  }
  return url;
};

export const toDataURL = async (link, token = "") => {
  if (link.includes("/resources/files"))
    return await authenticateUrl(link, token);
  else return await CORStoDataURL(link);
};

export const convertProductsImagesToDataURL = async (products, token) => {
  const arr = [];
  if (!products) return;
  for (const p of products) {
    let url = "";
    if (p.imageURL && p.imageURL !== "") {
      url = await toDataURL(p.imageURL, token);
      p.imageURLTmp = url;
    }
    arr.push(p);
  }
  return arr;
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
export const emailRegex = "^[^@]+@[^@]+\.[^@]*com(\.[^@]*)*$";
export const validateField = (
  name,
  field,
  isEmpty = false,
  withinRange = false,
  min = 1,
  max = 10,
  expression = undefined
) => {
  const regular = RegExp(expression);
  let message = ``;
  let isValid = true;
  if ((isEmpty && !field) || (isEmpty && field.length === 0)) {
    isValid = false;
    message += `\nCampo '${name}' não pode estar vazio.\n`;
  }

  if (
    (field !== "" && withinRange && field.length < min) ||
    (field !== "" && withinRange && field.length > max)
  ) {
    isValid = false;
    message += `\nCampo '${name}' deve ter entre ${min} e ${max} caracteres.\n`;
  }
  if (field !== "" && expression && !regular.test(field)) {
    isValid = false;
    const chars =
      name === "senha" ? "letra maiúscula, minúscula e um número" : "@ e .com";
    message += `\nCampo '${name}' deve conter '${chars}'.\n`;
  }
  return { isValid: isValid, message: message };
};

export const searchIntoCollection = (query, field, collection) => {
  if (!collection || !query) return [];
  const keywords = query.split(" ");
  const products = [];
  collection.forEach((p) => {
    keywords.forEach((key) => {
      if (key.length < 3) return;
      if (p.name.toLowerCase().includes(key.toLowerCase())) products.push(p);
    });
  });
  return products;
};

export const equalsIgnoreCase = (value1, value2) => {
  if(!value1 || !value2 ) return false;
  value1 = value1.toLowerCase();
  value2 = value2.toLowerCase();
  return value1 === value2;
}

export const copyProductPropertiesWithoutStyles = (copyFrom, copyTo) => {
  copyTo.name = copyFrom.name;
  copyTo.imageURLTmp = copyFrom.imageURLTmp;
  copyTo.imageURL = copyFrom.imageURL;
  copyTo.price = copyFrom.price;
  copyTo.measurement = copyFrom.measurement;
  return copyTo;

}

export const copyProductPropertiesWithStyles = (copyFrom, copyTo) => {
  copyTo = copyProductPropertiesWithoutStyles(copyFrom, copyTo);
  copyTo.styles.priceStyle = copyFrom.styles.priceStyle;
  copyTo.styles.bgColor = copyFrom.styles.bgColor;
  copyTo.styles.txtColor = copyFrom.styles.txtColor;
  copyTo.styles.priceTxtColor = copyFrom.styles.priceTxtColor;
  copyTo.styles.priceBgColor = copyFrom.styles.priceBgColor;
  return copyTo;
}