import { useEffect } from "react";
import styled from "styled-components";

export const List = styled.ul`
  gap: 3rem;
  width: 100%;
  position: relative;
`;

export const ProductItem = styled.li`
  align-items: center;
  width: 100%;
  user-select: none;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: bold;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  padding: 0 1.2rem;
  &:hover {
    background-color: #d97706;
  }
  span:first-letter {
    text-transform: uppercase;
  }
  img {
    width: 5rem;
    height: 5rem;
  }

  .name-price-wrapper {
    gap: 3rem;
  }

  .no-image-span {
    font-size: 1.2rem;
    width: min-content;
    text-align: center;
  }
`;

export function ListProduct({ product, action }) {
  return (
    <ProductItem
      className="flex"
      onClick={() => {
        action(product);
      }}
    >
      <div className="name-price-wrapper flex">
        <span>{product.name}</span>
        <div className="price-unity-wrapper flex direction-column">
          <span className="price">R${product.price}</span>
          <span className="unity">{product.measurement}</span>
        </div>
      </div>
      {product.imageURL && product.imageURLTmp ? (
        <img src={product.imageURLTmp} alt="" />
      ) : (
        <span className="no-image-span">Sem imagem</span>
      )}
    </ProductItem>
  );
}
