import React, { useEffect, useState } from "react";
import StoriesCanvas, {
  BrandNamePhraseWrapper,
  calcStoriesGrid,
} from "./StoriesCanvas";
import styled, { css } from "styled-components";
import {
  BrandLogo,
  calcProductStylesOcurrence,
  Canvas,
  Contacts,
  Footer,
  GridProducts,
  Header,
} from "../Canvas/CanvasGeneralStyles";
import {
  CANVAS_TARGET_CLASSNAME,
  PANFLETO_DIM,
} from "../EncarteStyleConstants";
import Product from "../../../sharable/EncarteProduct";
import ExpirationDate, {
  ExpirationDateStyle,
} from "../../../sharable/ExpirationDate";
import BrandAddress, { AddressStyle } from "../../../sharable/BrandAddress";
import { IonIcon } from "@ionic/react";
import {
  globeSharp,
  logoFacebook,
  logoInstagram,
  logoWhatsapp,
} from "ionicons/icons";
import BrandContacts, { ContactsContainer, ContactsStyle } from "../../../sharable/BrandContacts";

const PanfletoCanvasContainer = styled(Canvas)`
  ${({ scale, styles }) => css`
    background-position-y: ${-440 * scale}px;
  `}
`;
const PanfletoHeader = styled(Header)`
  ${({ scale, styles }) => css`
  ${BrandLogo} {
    bottom: ${240 * scale}px;
    right: ${250*scale}px;
      transform: scale(2);
      }
      
  `}
`;
const PanfletoGridProducts = styled(GridProducts)`
  
`;
const PanfletoFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  padding: 0;

  justify-content: space-between;
  ${({ scale, styles }) => css`
    ${ExpirationDateStyle} {
      align-self: center;
      width: 100%;
      margin-top: 0;
      text-align: center;
      font-size: ${42 * scale}px;
      background-color: ${styles.priceTxtColor};
      color: ${styles.priceBgColor};
    }
    ${AddressStyle} {
      width: 100%;
      border-radius: 0;
      font-weight: bold;
      text-transform: uppercase;
      align-items: center;

      .logo {
        font-size: ${48 * scale}px !important;
      }
      p {
        margin-top: 0;
        font-size: ${42 * scale}px;
        text-align: center;
      }
    }

    ${ContactsStyle} {

    gap: ${14 * scale}px;
    span {
      font-size: ${42 * scale}px;
    }
    .logo-social-wrapper {
      padding: 0 ${16 * scale}px;
      border-radius: 50px;
      justify-content: center;
    }
  
    }
  `}
`;

const BrandNameContactsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ scale }) => css`
    padding: 0 ${50 * scale}px;
    h2 {
      font-size: ;
    }
  `}
`;

const PanfletoBrandNamePhraseWrapper = styled(BrandNamePhraseWrapper)`
  ${({ scale, styles, brand }) => {
    const phraseSize = `${
      brand.phrase && brand.phrase.length > 48 ? 36 * scale : 42 * scale
    }px`;
    const nameSize = `${
      brand.name && brand.name.length > 25 ? 64 * scale : 72 * scale
    }px`;
    return css`
      h2 {
        font-size: ${nameSize};
      }

      p {
        font-size: ${phraseSize};
      }
    `;
  }}
`;

export default function PanfletoCanvas({
  styles,
  scale,
  brand,
  encarte,
  model,
  products,
}) {
  const [headerAndFooterStyles, setHeaderAndFooterStyles] = useState(undefined);
  useEffect(() => {
    setHeaderAndFooterStyles(calcProductStylesOcurrence(products));
  },[products])

  return (
    <PanfletoCanvasContainer
      scale={scale}
      styles={styles}
      model={model}
      dimensions={PANFLETO_DIM}
      className={CANVAS_TARGET_CLASSNAME}
    >
      <PanfletoHeader styles={styles} scale={scale}>
        {" "}
        {headerAndFooterStyles && 

        <BrandLogo
        logo={brand.logoURLTmp}
        scale={scale}
        logoSize={styles.logoSize}
        headerStyles={headerAndFooterStyles}
        encarteStyles={styles}
        />
      }

      </PanfletoHeader>
      <PanfletoGridProducts
        grid={calcPanfletoGrid(products.length)}
        styles={styles}
        scale={scale}
      >
        {products.map((product) => (
          <Product product={product} scale={scale} styles={styles} />
        ))}
      </PanfletoGridProducts>
      {headerAndFooterStyles && 
      <PanfletoFooter styles={styles} model={model} scale={scale}>
        <ExpirationDate
          endDate={encarte.endDate}
          startDate={encarte.startDate}
          styles={headerAndFooterStyles}
          scale={scale}
          />
        <BrandNameContactsWrapper scale={scale}>
          <PanfletoBrandNamePhraseWrapper
            scale={scale}
            brand={brand}
            styles={styles}
            >
            <h2>{brand.name}</h2>
            <p>{brand.phrase}</p>
          </PanfletoBrandNamePhraseWrapper>
          <BrandContacts brand={brand} styles={headerAndFooterStyles} scale={scale}/>
        </BrandNameContactsWrapper>
        <BrandAddress brand={brand} scale={scale} styles={headerAndFooterStyles} />
      </PanfletoFooter>
          }
    </PanfletoCanvasContainer>
  );
}

const calcPanfletoGrid = (productsSize) => {
  const MAX_COLUMNS = 7;
  const MAX_ROWS = 7;
  const grid = { columns: "", rows: "", highLight: undefined };
  const evenCount = productsSize % 2 === 0 ? productsSize : productsSize + 1;
  if (productsSize === 1) {
    grid.columns = 1;
    grid.rows = 1;
  } else if (evenCount === 4) {
    grid.columns = 2;
    grid.rows = 2;
  } else {
    for (let j = MAX_ROWS; j >= 1; j--) {
      for (let i = 1; i <= MAX_COLUMNS; i++) {
        if (i * j === evenCount && j > grid.rows) {
          grid.columns = i;
          grid.rows = j;
          break;
        }
      }
    }
  }

  grid.highLight = grid.columns * grid.rows != productsSize ? "row" : undefined;
  return grid;
};
