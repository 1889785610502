import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import { useOutletContext } from "react-router";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import Price from "../../sharable/Price";
import Styles from "../../../OBJRep/Styles";
import {
  DEFAULT_PRICE_STYLE,
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  ROUNDED_PRICE_STYLE,
} from "../CanvasViewer/EncarteStyleConstants";

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  h2 {
    text-transform: capitalize;
  }
  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
          overflow: hidden;
      main {
        filter: blur(3px);
      }
    `}
`;

const PricesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Wrapper = styled.div`

  border: 1px solid transparent;
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border-color: #d97706;
  }
  transition: all 300ms ease-in-out;
  ${({ selected }) => {
    if (selected)
      return css`
        background-color: #d97706;
      `;
  }}
`;

const styles1 = Styles();
const styles2 = Styles();
const styles3 = Styles();
styles1.priceStyle = DEFAULT_PRICE_STYLE;
styles2.priceStyle = ROUNDED_PRICE_STYLE;
styles3.priceStyle = ROUGHLY_ROUNDED_PRICE_STYLE;

export default function PricesMenuView({ props, updateProductStyle, selectedProduct }) {
  const { activeAccount, isAccountPremium } = useOutletContext();
  const [currentPriceStyle, setCurrentPriceStyle] = useState(DEFAULT_PRICE_STYLE);

  useEffect(() => {
    if(selectedProduct && selectedProduct.styles.priceStyle) 
      setCurrentPriceStyle(selectedProduct.styles.priceStyle)
   
      else setCurrentPriceStyle(DEFAULT_PRICE_STYLE);
  }, [selectedProduct])
  const handlePriceChange = (e) => {
    const element = e.target.closest(".price-style");
    if (!element) return;

    const style = element.getAttribute("data-price-style");
    setCurrentPriceStyle(style);
    updateProductStyle("priceStyle", style)
  };

  return (
    <>
      <Container isAccountPremium={isAccountPremium}>
        {!isAccountPremium && <PremiumContentProtectiveLayer />}
        <main>
    
        <h2>Preço</h2>
          <PricesWrapper onClick={handlePriceChange}>
            <Wrapper
              className="price-style"
              data-price-style={DEFAULT_PRICE_STYLE}
              selected={
                currentPriceStyle === DEFAULT_PRICE_STYLE
                  ? true
                  : false
              }
            >
              <Price
                styles={styles1}
                scale={0.8}
                unity={"cada"}
                price={"19,99"}
                position="relative"
              />
            </Wrapper>
            <Wrapper
              className="price-style"
              data-price-style={ROUNDED_PRICE_STYLE}
              selected={
                currentPriceStyle === ROUNDED_PRICE_STYLE
                  ? true
                  : false
              }
            >
              <Price
                styles={styles2}
                scale={0.8}
                unity={"cada"}
                price={"19,99"}
                position="relative"
              />
            </Wrapper>

            <Wrapper
              className="price-style"
              data-price-style={ROUGHLY_ROUNDED_PRICE_STYLE}
              selected={
                currentPriceStyle === ROUGHLY_ROUNDED_PRICE_STYLE
                  ? true
                  : false
              }
            >
              <Price
                styles={styles3}
                scale={0.8}
                unity={"cada"}
                price={"19,99"}
                position="relative"
              />
            </Wrapper>
          </PricesWrapper>
        </main>
      </Container>
    </>
  );
}
