import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  MenuDescription,
  MenuTitle,
} from "../WorkSpace/ToolBarMenuViews/Toolbar";
import { useOutletContext } from "react-router";
import { copyProductPropertiesWithoutStyles, searchIntoCollection, toDataURL } from "../../services/Utils";
import Button from "../sharable/ActionButton";
import { Input } from "../sharable/InputStyle";
import { Selector } from "../WorkSpace/CanvasViewer/EncarteStyle";
import { UNITYS } from "../WorkSpace/CanvasViewer/EncarteStyleConstants";
import Loading from "../sharable/Loading";
import { searchProductImages } from "../../services/ProductImageService";
import { Image } from "../sharable/EncarteProduct";
import { BoxImage } from "../WorkSpace/ToolBarMenuViews/ProductsMenuView";
import ProductObj from "../../OBJRep/Product";
import PopUp from "../sharable/PopUp";
import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import PremiumContentProtectiveLayer from "../sharable/PremiumContentProtectiveLayer";
import { ListProduct, ProductItem } from "../sharable/ListProduct";
import ProductStyles from "../../OBJRep/ProductStyles";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 8.4rem);
  overflow-y: scroll;
  overflow-x: hidden;

  .query-input {
    width: 100%;
    margin-bottom: 3rem;
  }

  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      overflow-y: hidden;
      overflow-x: hidden;
      height: 100vh;
      main {
        filter: blur(3px);
      }
    `}

  .menu-title {
    margin-top: 3rem;
  }
`;

const List = styled.ul`
  gap: 3rem;
  width: 100%;
  position: relative;
  overflow: auto;

  .loading-message-wrapper {
    min-width: 100%;
    min-height: 10rem;
  }
`;

const ProductFormWrapper = styled.div`
  overflow: hidden;
  margin: 1.6rem 0;
  position: relative;

  .close-form-span {
    font-size: 1.2rem;
    border: 1px solid #fff;
    padding: 0.4rem 0.8rem;
    width: 40%;
    justify-content: space-around;
    margin-bottom: 1.2rem;
    font-weight: bold;
    transition: all 300ms;
    cursor: pointer;
    &:hover {
      background-color: #d97706;
      border-color: transparent;
    }
  }
`;

const ProductButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  .product-count {
    font-size: 1.4rem;
    display: inline-block;
    margin-bottom: 2rem;
  }
`;
const NewProductForm = styled.div`
  transform: translateY(-100%);
  transition: all 400ms ease-in-out;
  height: 0;
  ${({ showForm }) =>
    showForm &&
    css`
      height: min-content;
      transform: translateY(0);
    `}
`;
const GRID_FULL_MESSAGE =
  "Sua grade de produtos está cheia! Para poder adicionar mais produtos escolha uma grade com uma quantidade maior de produtos.";
const PRODUCT_ALREADY_EXISTS_MESSAGE =
  "Esse produto já foi inserido no encarte. Encartes não podem possuir itens duplicados";
const PRODUCT_NOT_DELETABLE_MESSAGE =
  "Esse produto não pode ser deletado pois está sendo utilizado em outros encartes!";
const PRODUCT_CREATED_SUCCESSFULLY_MESSAGE = "Produto criado com sucesso!";
const PRODUCT_UPDATED_SUCESSFULLY_MESSAGE = "Produto atualizado com sucesso!";
const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
export default function MyProductsView({
  encarteProducts,
  updateEncarteProducts,
  grid,
  userProducts,
  updateUserProducts,
}) {
  const { token, isAccountPremium } = useOutletContext();
  const [showUploadProductForm, setShowUploadProductForm] = useState(false);
  const [showUpdateProductForm, setShowUpdateProductForm] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [productNameInputSearch, setProductNameInputSearch] = useState("");
  const [toUploadProduct, setToUploadProduct] = useState();
  const [toUpdateProduct, setToUpdateProduct] = useState();
  const [searchImageResults, setSearchImageResults] = useState();
  const [encarteProductsCache, setEncarteProductsCache] =
    useState(encarteProducts);

  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpMessage, setPopUpmessage] = useState();
  const addProductToEncarte = async (product) => {
    const copy = [...encarteProductsCache];
    let productExists = false;
    let hasGridSpaceAvailable = false;
    let gridSpaceIndex = undefined;
    for(let i = 0; i< encarteProductsCache.length; i++) {
      if (encarteProductsCache[i].name === product.name) productExists = true;
      if (encarteProductsCache[i].name.toLowerCase().includes("nome do produto")) {
        hasGridSpaceAvailable = true;
        gridSpaceIndex = i;
        break;
      }
   
    }
    if (productExists) {
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_ALREADY_EXISTS_MESSAGE);
      return;
    } else if (grid && !hasGridSpaceAvailable) {
      setPopUpmessage(GRID_FULL_MESSAGE);
      setShowPopUp(true);
      return;
    }
    //    product.imageURLTmp = await toDataURL(product.imageURL, token);

    if (grid) {
      product.id = gridSpaceIndex;
      copy[gridSpaceIndex] = copyProductPropertiesWithoutStyles(product, copy[gridSpaceIndex])
    } else {
      product.styles = ProductStyles();
      copy.push(product);
    }
    updateEncarteProducts(copy);
    setEncarteProductsCache(copy);
  };
  const watchUserTyping = useEffect(() => {
    setIsUserTyping(true);
    const timeout = setTimeout(() => {
      if (!productNameInputSearch) return;

      setIsUserTyping(false);
    }, 1200);
    return () => clearTimeout(timeout);
  }, [productNameInputSearch]);

  const createNewProduct = async () => {
    if (!toUploadProduct.name) return;
    const res = await fetch(`${SERVER_BASE_URL}products`, {
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(toUploadProduct),
    });
    if (res.ok) {
      const userProductsCopy = [...userProducts];
      userProductsCopy.push(toUploadProduct);
      updateUserProducts(userProductsCopy);
      setToUploadProduct(null);
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_CREATED_SUCCESSFULLY_MESSAGE);
      clearFormFields();
    }
  };
  const handleImageClick = (image, object, callback) => {
    if (!image) return;
    handleFormUpdate("imageURL", image.url, object, callback);
    handleFormUpdate("imageURLTmp", image.displayUrl, object, callback);
  };

  const handleFormUpdate = (prop, value, object, callback) => {
    const copy = { ...object };
    copy[prop] = value;

    callback(copy);
  };

  const clearFormFields = () => {
    document.querySelector(".product-name").value = "";
    document.querySelector(".product-price").value = "";
    setSearchImageResults([]);
  };

  const deleteProduct = async (product) => {
    const res = await fetch(`${SERVER_BASE_URL}products/${product.id}`, {
      headers: { Authorization: token },
      method: "DELETE",
      body: JSON.stringify(toUploadProduct),
    });
    if (res.ok) {
      const nArr = userProducts.filter((p) => p.id != product.id);
      updateUserProducts(nArr);
      alert("Produto deletado com êxito");
    } else if (res.status === 500) {
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_NOT_DELETABLE_MESSAGE);
    }
  };

  const updateProduct = async () => {
    const res = await fetch(`${SERVER_BASE_URL}products`, {
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "PUT",
      body: JSON.stringify(toUpdateProduct),
    });
    if (res.ok) {
      setShowPopUp(true);
      setPopUpmessage(PRODUCT_UPDATED_SUCESSFULLY_MESSAGE);
      const nArr = [];
      /*UPDATING USER PRODUCT LIST */
      userProducts.slice().forEach((product) => {
   
        if (product.id === toUpdateProduct.id) {
          product = toUpdateProduct;
        }
        nArr.push(product);
      });
      updateUserProducts(nArr);
    
    /*UPDATING PRODUCT ON ENCARTE */
    const updatedEncarteProducts = encarteProducts.map(p => {
      if(p.id === toUpdateProduct.id) {
        p = copyProductPropertiesWithoutStyles(toUpdateProduct, p);
      } 
      return p;
    })
    updateEncarteProducts(updatedEncarteProducts);
    }
  };

  return (
    <Container
      isAccountPremium={isAccountPremium}
      className="menu-view-container"
    >
      {!isAccountPremium && <PremiumContentProtectiveLayer />}
      <main className="main-view">
        <MenuDescription>
          Você pode criar encartes adicionando produtos já cadastrados aqui!
        </MenuDescription>
        <PopUp
          setShowPopUp={setShowPopUp}
          text={popUpMessage}
          showPopUp={showPopUp}
          buttonText={"Ok"}
          position="fixed"
          top="0rem"
          right="2rem"
        />
        {!showUploadProductForm && !showUpdateProductForm && (
          <Button
            $NoBgColor
            $fullWidth
            text="Registrar novo produto +"
            color="#fff"
            width="calc(100% - 3px)"
            action={() => {
              setToUploadProduct(ProductObj());
              setShowUploadProductForm((t) => true);
            }}
            size="1.4"
          />
        )}

        {showUploadProductForm && (
          <ProductForm
            product={toUploadProduct}
            action={createNewProduct}
            buttonText={"Salvar novo produto"}
            setShowForm={setShowUploadProductForm}
            showForm={showUploadProductForm}
            clearFormFields={clearFormFields}
            handleFormUpdate={handleFormUpdate}
            handleImageClick={handleImageClick}
            searchImageResults={searchImageResults}
            setSearchImageResults={setSearchImageResults}
            formUpdateCallback={setToUploadProduct}
          />
        )}
        {showUpdateProductForm && (
          <ProductForm
            product={toUpdateProduct}
            action={updateProduct}
            buttonText={"Atualizar produto"}
            setShowForm={setShowUpdateProductForm}
            showForm={showUpdateProductForm}
            clearFormFields={clearFormFields}
            handleFormUpdate={handleFormUpdate}
            handleImageClick={handleImageClick}
            searchImageResults={searchImageResults}
            setSearchImageResults={setSearchImageResults}
            className="update-product-form"
            formUpdateCallback={setToUpdateProduct}
          />
        )}
        <MenuTitle className="menu-title">Meus produtos</MenuTitle>
        <Input
          className="query-input"
          type="text"
          placeholder="Digite aqui para buscar um produto..."
          onChange={(e) => {
            setProductNameInputSearch(e.target.value);
          }}
        />

        <List className="flex direction-column justify">
          {isUserTyping && productNameInputSearch ? (
            <div className="loading-message-wrapper">
              <Loading
                $NoBg
                txt={`Buscando produto ${productNameInputSearch}...`}
                color="#fff"
              />
            </div>
          ) : productNameInputSearch != "" && !isUserTyping ? (
            searchIntoCollection(
              productNameInputSearch,
              "name",
              userProducts
            ).map((product) => (
              <>
                <ListProduct
                  product={product}
                  action={(product) => addProductToEncarte(product)}
                />
                <div className="flex min-gap space-between">
                  <Button
                    text={"Editar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => {
                      setShowUpdateProductForm(true);
                      setToUpdateProduct(product);
                      const container = document.querySelector(".main-view");
                      container.scrollIntoView({ behavior: "smooth" });
                    }}
                    size={"1.2"}
                  />
                  <Button
                    text={"Deletar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => deleteProduct(product)}
                    size={"1.2"}
                  />
                </div>
              </>
            ))
          ) : userProducts ? (
            userProducts.map((product, i) => (
              <ProductButtonWrapper>
                <div>
                  <span className="product-count">Produto {i + 1}.</span>
                  <ListProduct
                    product={product}
                    action={(product) => addProductToEncarte(product)}
                  />
                </div>
                <div className="flex min-gap space-between">
                  <Button
                    text={"Editar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => {
                      setShowUpdateProductForm(true);
                      setToUpdateProduct(product);
                      const container = document.querySelector(".main-view");
                      container.scrollIntoView({ behavior: "smooth" });
                    }}
                    size={"1.2"}
                  />
                  <Button
                    text={"Deletar"}
                    $NoBgColor
                    width="10rem"
                    color="#fff"
                    action={() => deleteProduct(product)}
                    size={"1.2"}
                  />
                </div>
              </ProductButtonWrapper>
            ))
          ) : (
            <Loading $NoBg color="#fff" txt="Carregando seus produtos..." />
          )}
        </List>
      </main>
    </Container>
  );
}
/**    */
const ProductForm = ({
  showForm,
  setShowForm,
  clearFormFields,
  product,
  handleFormUpdate,
  action,
  buttonText,
  handleImageClick,
  searchImageResults,
  setSearchImageResults,
  formUpdateCallback,
}) => {
  const { token } = useOutletContext();
  const [formProduct, setFormProduct] = useState(product);
  useEffect(() => {
    setFormProduct(product);
  }, [product]);
  return (
    <>
      <ProductFormWrapper>
        <NewProductForm
          showForm={showForm}
          className="flex direction-column min-gap"
        >
          <span
            className="close-form-span flex align"
            onClick={() => {
              setShowForm(false);
              clearFormFields();
            }}
          >
            Fechar formulário <IonIcon icon={close} />
          </span>
          <div className="product-image-wrapper flex justify align">
            {product && product.imageURL != "" ? (
              <Image
                url={product.imageURLTmp}
                height="9rem"
                backgroundSize="contain"
              />
            ) : (
              "A imagem do produto aparecerá aqui"
            )}
          </div>

          <Input
            type="text"
            className="product-name"
            placeholder="Nome do produto..."
            defaultValue={formProduct ? formProduct.name : ""}
            onChange={(e) => {
              handleFormUpdate(
                "name",
                e.target.value,
                formProduct,
                formUpdateCallback
              );
            }}
          />
          <Input
            type="text"
            placeholder="Valor do produto... Ex: 5,99"
            className="product-price"
            defaultValue={formProduct ? formProduct.price : ""}
            onChange={(e) => {
              handleFormUpdate(
                "price",
                e.target.value,
                formProduct,
                formUpdateCallback
              );
            }}
          />
          <Selector
            callback={(value) =>
              handleFormUpdate(
                "measurement",
                value,
                formProduct,
                formUpdateCallback
              )
            }
            options={UNITYS}
            selected={
              formProduct && formProduct.measurement
                ? formProduct.measurement
                : UNITYS[0]
            }
            txt="Valor por"
          />
          <BoxImage
            imagesCount={searchImageResults && searchImageResults.length}
          >
            {searchImageResults &&
              searchImageResults.map((image, id) => {

                return (
                  <Image
                    onClick={() => {
                      const p = formProduct;
                      p.imageURLTmp = image.displayUrl;
                      handleFormUpdate(
                        "imageURL",
                        image.url,
                        p,
                        formUpdateCallback
                      );
                    }}
                    url={`${image.displayUrl}`}
                    height="9rem"
                    hover={true}
                    key={`image-${id}`}
                    backgroundSize="contain"
                  />
                );
              })}
          </BoxImage>
          <Button
            color={"#fff"}
            $fullWidth
            size="1.4"
            $NoBgColor
            text={"Buscar imagens"}
            width="calc(100% - 2px)"
            action={async () => {
              if (!product || product.name.length < 3) return;
              const results = await searchProductImages(product.name, token, 0);
              setSearchImageResults(results);
            }}
          />
        </NewProductForm>
      </ProductFormWrapper>
      {showForm ? (
        <Button
          $fullWidth
          text={buttonText}
          color="#fff"
          width="calc(100% - 3px)"
          action={async () => {
            await action(product);
            setShowForm((t) => false);
          }}
          size="1.4"
        />
      ) : (
        ""
      )}
    </>
  );
};

export const loadUserProducts = async (token) => {
  const res = await fetch(`${SERVER_BASE_URL}products`, {
    headers: {
      Authorization: token,
    },
  });
  const json = await res.json();

  const images = await Promise.all(
    json.map((product) => {
      return toDataURL(product.imageURL, token);
    })
  );
  const pCopy = [...json];
  images.forEach((image, i) => (pCopy[i].imageURLTmp = image));
  return pCopy;
};
