import React, { useContext, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useLoaderData, useNavigate, useOutletContext } from "react-router";
import EncarteCard from "../sharable/EncarteCard.jsx";
import { createSearchParams } from "react-router-dom";
import { ModalHeader } from "../../SharableStyles/FormStyle.js";
import Button from "../sharable/ActionButton.jsx";
import Cookies from "js-cookie";
import Encarte from "../../OBJRep/Encarte.js";
import { devices } from "../../Devices.js";
import {
  loadEncarteModel,
  loadEncarteModels,
} from "../../services/ModelsService.js";
import Logo from "../sharable/Logo.jsx";
import Loading from "../sharable/Loading.jsx";
import { IonIcon } from "@ionic/react";
import { logOut, menu, menuOutline } from "ionicons/icons";
import { cleanTokenCookie } from "../../TokenCookie.js";
import PopUp from "../sharable/PopUp.jsx";
import { requestValidationCodeNotification } from "./EmailValidation.jsx";
import { ModalPopUp } from "../sharable/ModalPopup.jsx";
import { loadLogo } from "../../services/BrandLogoService.js";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Container = styled.div`
  width: 100vw;
  padding-bottom: 5.6rem;
  h1 {
    font-size: 2.4rem;
    font-weight: normal;
    margin-top: 3rem;
  }

  @media ${devices.tablet} {
    gap: 1rem;
  }
`;

const Header = styled.header`
  padding: 2rem 4.2rem;
`;

const Nav = styled.li`
  margin-top: 2rem;
  list-style: none;
  display: flex;
  gap: 3.6rem;
  text-transform: capitalize !important;
  @media ${devices.tablet} {
    display: none;
  }
`;

const NavItem = styled.ul`
  font-size: 1.8rem;
  cursor: pointer;
  @media ${devices.tablet} {
    font-size: 1.6rem;
  }
  &&:hover {
    color: #ae5f05;
    transition: all 300ms ease-in-out;
    @media ${devices.tablet} {
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #fff;
    }
  }
`;

const Anchor = styled.a`
  all: unset;
`;

const Main = styled.main`
  padding: 0 10rem;
  gap: 5rem;
  margin-top: 7.2rem;
  h1 {
    text-transform: uppercase;
  }

  .welcome-subscription-end-date-wrapper {
    text-align: right;
  }

  h2 {
    font-weight: 500;
    text-align: right;
  }

  span {
    font-size: 1.2rem;
  }
  p {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
  }
  @media ${devices.laptop} {
    padding: 5rem;
    h1 {
      text-align: center;
    }

    @media ${devices.tablet} {
      margin-top: 0;

      h1 {
        text-align: left;
      }
    }
  }
`;

const EncartesContainer = styled.ul`
  position: relative;
  margin-bottom: 3.6rem;
  display: grid;
  grid-template-columns: repeat(6, min-content);
  gap: 4.2rem;
  justify-content: center;
  ${({ $Models }) =>
    $Models &&
    css`
      grid-template-columns: repeat(5, min-content);
      justify-content: center;
      //gap: 3rem !important;
      z-index: 10;
    `}

  @media ${devices.laptop} {
    grid-template-columns: repeat(4, min-content);
  }
  @media ${devices.tablet} {
    gap: 2.8rem;
  }
  @media ${devices.mobileL} {
    grid-template-columns: repeat(3, min-content);
    gap: 2.4rem;
  }
`;

const AppearAnimation = keyframes`
0% {
  opacity: 0%;
}

100% {
  opacity: 100%;
}
`;

const SubscriptionPopUp = styled.div`
  h1:first-letter {
    text-transform: uppercase;
  }
  z-index: 10;
  line-height: 1.2;
  max-width: 50rem;
  p {
    margin-top: 1.2rem;
    margin-bottom: 2rem;
  }
  border: none !important;
  position: absolute;
  right: 50%;
  top: 20rem;
  transform: translateX(50%);
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(1, 1, 1, 0.12);
  .assinar-button {
    all: unset;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding: 0.6rem 1.2rem;
    border-radius: 2px;
    display: inline-block;
    transition: all 300ms ease-in-out;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      border-color: transparent;
      background-color: #d97706;
      color: #fff;
    }
  }
  animation: ${AppearAnimation} 500ms linear;
  @media ${devices.tablet} {
    width: 80%;
    h1 {
      font-size: 1.8rem;
    }
  }
`;

const ActionButtonsCardWrapper = styled.div`
  .action-buttons-wrapper {
    width: 100%;
  }
`;

const ActionButton = styled.button`
  all: unset;
  width: 100%;
  margin-top: 0.8rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid black;
  border-radius: 2px;
  padding: 0.4rem 0;
  font-weight: 600;
  &:hover {
    background-color: #d97706;
    border-color: transparent;
    color: #fff;
    transition: all 300ms ease-in-out;
  }
`;

const UpdatesInfoContainer = styled.div`
  margin-top: 5.4rem;
  justify-content: center;
  h1 {
    margin-bottom: 3rem;
    text-align: center;
  }
`;

const UpdatesInfoList = styled.ul`
  width: 100%;
  gap: 4.2rem;
  align-items: center;
  flex-shrink: 0;
`;

const UpdateInfo = styled.li`
  width: 70%;
  h2 {
    font-size: 2rem;
    background-color: #fb923c;
    text-align: center;
    color: #fff;
  }
  p {
    border-bottom: 0.5px solid black;
    padding: 1rem 0;
    a:visited,
    a:link {
      all: unset;
      color: #d97706;
      border-bottom: 0.5px solid #d97706;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
    div {
      display: inline;
      color: #d97706;
    }
  }
`;

const EmailValidationHeadline = styled.div`
  color: #fff;
  width: 100vw;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 0;
  background-color: #d97706;

  a {
    transition: all 300ms ease-in-out;
    color: #fff;

    &:hover {
      color: #ae5f05;
    }
  }
`;

//COMPONENT VERIFIED 31/08;
export default function Home() {
  const navigate = useNavigate();

  const { user, token, setUser, activeAccount, subscriptionCancelAt } =
    useOutletContext();
  const [showSubscriptionPopUp, setShowSubscriptionPopUp] = useState(false);
  const [showEmailValidationMessage, setShowEmailValidationMessage] =
    useState(false);
  const [maxEncarteCreationReached, setMaxEncarteCreationReached] =
    useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);

  const updateEncarteModel = useEffect(() => {
    if (!user.encartes || user.encartes.length <= 0) {
      setModelsLoaded(true);
      return;
    }
    const perform = async () => {
      const userCopy = { ...user };
      sortByLastModified(userCopy.encartes);
      userCopy.encartes = await Promise.all(
        userCopy.encartes.map(async (e) => {
          const model = await loadEncarteModel(e.modelUrl, token);
          e.urlTmp = model.urlTmp;
          return e;
        })
      );
      setUser(userCopy);
      setModelsLoaded(true);
    };

    perform();
  }, []);

  const handleEncarteCreation = async () => {
    const userCopy = { ...user };
    const url = BASE_URL + "encarte-collection/";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(Encarte()),
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const encarte = await response.json();
      userCopy.encartes.push(encarte);
      setUser(userCopy);
      navigate({
        pathname: "/editor",
        search: createSearchParams({
          encarteId: encarte.id,
        }).toString(),
      });
    } else if (response.status === 403) {
      setMaxEncarteCreationReached(true);
    }
  };

  const handleEncarteDelete = async (id) => {
    const url = `${BASE_URL}encarte-collection/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    const userCopy = { ...user };
    const encarteArray = userCopy.encartes.filter((e) => e.id !== id);
    userCopy.encartes = encarteArray;
    setUser(userCopy);
    if (response.ok) alert("Encarte deletado!");
    else
      alert(
        "Não foi possível realizar essa operação. Tente recarregar a página e remover novamente."
      );
  };

  const handleEncarteUpdate = async (id) => {
    navigate(`/editor?encarteId=${id}&update=true`);
  };
  return (
    <Container className="flex direction-column">
      {!user.accountVerified && (
        <EmailValidationHeadline>
          Você precisa verificar o seu email!
          <Button
            text={"Verificar agora"}
            color="#fff"
            size="1.2"
            action={async () => {
              const res = await requestValidationCodeNotification(token);
              if (res.ok) {
                navigate("/verificacao-email");
              }
            }}
          />
        </EmailValidationHeadline>
      )}
      {user && (
        <>
          <PopUp
            text={"Você atingiu a quantidade máxima de encartes do dia!"}
            showPopUp={maxEncarteCreationReached}
            setShowPopUp={setMaxEncarteCreationReached}
            buttonText={"Ok"}
          />
          {showSubscriptionPopUp && (
            <ModalPopUp
              buttonAction={() =>
                navigate(activeAccount ? "/assinatura" : "/planos")
              }
              buttonText="Ver planos"
              title="Assine agora e potencialize suas vendas!"
              text="Com o ofertas express você cria suas ofertas em minutos. É
                  rápido e prático!"
              showActionButton={true}
            />
          )}

          {showEmailValidationMessage && (
            <ModalPopUp
              showActionButton={true}
              buttonAction={async () => {
                const res = await requestValidationCodeNotification(token);
                if (res.ok) navigate("/verificacao-email");
              }}
              text={
                "Precisamos saber se esse email te pertence! Levará menos de 1 minuto ;)"
              }
              title={"Você precisa verificar o seu email antes de continuar"}
              buttonText={"Verificar agora!"}
            />
          )}

          <Header className="flex space-between align">
            <MobileMenu />
            <Logo />

            <Nav>{NavJSX()}</Nav>

            <Button
              text={"criar encarte"}
              action={async () => {
                if (!user.accountVerified) {
                  setShowEmailValidationMessage(true);
                  return;
                }
                if (!activeAccount) {
                  setShowSubscriptionPopUp(true);
                  return;
                }

                await handleEncarteCreation();
              }}
            />
          </Header>
          <Main className="flex direction-column">
            <div className="flex direction-column">
              <div className="welcome-subscription-end-date-wrapper">
                {user.encartes.length > 0 ? (
                  <h2>Bem vindo de volta, {user.name} ;)!</h2>
                ) : (
                  <h2>Bem vindo, {user.name} ;)!</h2>
                )}
                {subscriptionCancelAt && (
                  <span>
                    Sua fatura vence dia{" "}
                    {new Date(subscriptionCancelAt * 1000).toLocaleDateString(
                      "pt-BR"
                    )}
                  </span>
                )}
              </div>
              <h1>Meus encartes</h1>
            </div>

            <EncartesContainer key={user}>
              {modelsLoaded && user.encartes ? (
                user.encartes.map((e) => (
                  <ActionButtonsCardWrapper>
                    <EncarteCard
                      onDelete={() => {
                        handleEncarteDelete(e);
                      }}
                      onClick={() => {}}
                      name={e.name}
                      url={e.urlTmp}
                      encarte={e}
                    />
                    <div className="flex action-buttons-wrapper direction-column">
                      <ActionButton onClick={() => handleEncarteDelete(e.id)}>
                        Remover
                      </ActionButton>
                      <ActionButton onClick={() => handleEncarteUpdate(e.id)}>
                        Editar
                      </ActionButton>
                    </div>
                  </ActionButtonsCardWrapper>
                ))
              ) : (
                <Loading txt={"Carregando seus encartes..."} />
              )}
            </EncartesContainer>
            {user.encartes.length <= 0 && (
              <p>Você não possui nenhum encarte ainda :(</p>
            )}
          </Main>
        </>
      )}
    </Container>
  );
}
const sortByLastModified = (encartes) => {
  encartes.sort((a, b) => b.lastModified - a.lastModified);
};

const MenuContainer = styled.div`
  text-transform: capitalize !important;
  display: none;

  .menu-icon-wrapper {
    font-size: 1.2rem;
    flex-direction: row-reverse;
    gap: 0.6rem;
  }
  .menu-icon {
    font-size: 2rem;
  }
  @media ${devices.tablet} {
    display: inline-block;
  }
`;

const ShowMenuAnimation = keyframes`
0% {
  width: 0px
}
100% {
  width: 120px;
}
`;

const LateralMenu = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  animation: ${ShowMenuAnimation} 300ms linear;
  background-color: red;
  color: #fff;
  background-color: #ae5f05;
  padding: 1.4rem;
  gap: 1rem;
  height: 100vh;
  z-index: 10;
`;

const MobileMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClickOutside = (e) => {
    const target = e.target;
    if (!target.closest(".mob-menu")) setShowMenu(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <MenuContainer className="mob-menu" onClick={() => setShowMenu(true)}>
      <div className="menu-icon-wrapper flex align">
        <span>Menu</span> <IonIcon icon={menuOutline} className="menu-icon" />
      </div>
      {showMenu ? (
        <LateralMenu>
          <NavJSX />
        </LateralMenu>
      ) : (
        ""
      )}
    </MenuContainer>
  );
};

function NavJSX() {
  const navigate = useNavigate();
  const { user, activeAccount } = useOutletContext();
  return (
    <>
      <>
        <NavItem
          onClick={() => navigate(activeAccount ? "/assinatura" : "/planos")}
        >
          {activeAccount ? "minha assinatura" : "assinar"}
        </NavItem>

        <NavItem>
          {" "}
          <Anchor
            href="https://www.youtube.com/channel/UCDcSC98INa1A-su_jnxvT5Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aprenda aqui
          </Anchor>
        </NavItem>
        <NavItem>
          {" "}
          <Anchor
            href="https://wa.me/message/2GPGFCP27PUCD1"
            target="_blank"
            rel="noopener noreferrer"
          >
            ajuda
          </Anchor>
        </NavItem>
      </>
      <NavItem
        onClick={() => {
          cleanTokenCookie();
          navigate("/entrar");
        }}
      >
        <div className="flex align min-gap">
          desconectar <IonIcon icon={logOut} />
        </div>
      </NavItem>{" "}
      {user.authority === "ADMIN" && (
        <NavItem
          onClick={() => {
            navigate("/clientes");
          }}
        >
          Meus clientes
        </NavItem>
      )}
    </>
  );
}
